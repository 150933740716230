import React, { useState, useEffect }  from 'react';
import { TextAreaInput, Tooltip } from 'components/common';
import FilteredDrawings from 'components/system_commands/form_fields/FilteredDrawings.jsx';
import GetRequest from 'components/common/GetRequest.jsx';
import styles from 'components/system_commands/_style.module.scss';

const DrawingFiltering = ( { config, value, setValue, register, errors, disabled, setFetchInProgress} ) => {
  const [drawingIds, setDrawingIds] = useState( [] )
  const [formula, setFormula] = useState("")
  const [delayedFormula, setDelayedFormula] = useState("")
  const [descriptionVisible, setDescriptionVisible] = useState(false)
  const onChange = (e) => {
    setFormula( () => e.target.value )
  }

  const getFormula = () => {
    GetRequest({
      url: `/admin/get-user-param/admin_drawing_filtering_formula`,
      callback: ( data ) => {
        setFormula( () => data.admin_drawing_filtering_formula == undefined ? 'cizim_ve_varyasyonlari(23281)' :  data.admin_drawing_filtering_formula)
      }
    })
  }

  const toggleShowDescription = (e) => {
    setDescriptionVisible( () => !descriptionVisible )
  }

  const handleDelayed = (value) => {
    setDelayedFormula( () => value );
  }

  // useEffect starts
  useEffect(() => {
    const timeOutId = setTimeout(() => handleDelayed(formula), 500);
    return () => clearTimeout(timeOutId);
  }, [formula]);

  useEffect( () => {
    document.addEventListener('copy', (event) => {
      const selection = window.getSelection().toString().trim(); // Remove leading/trailing spaces
      event.clipboardData.setData('text/plain', selection);
      event.preventDefault(); // Prevent default copy behavior
    });
  }, [] )

  useEffect( () => {
    setValue( config.name, () => drawingIds )
  }, [drawingIds] )

  useEffect(getFormula, [])
// useEffect ends

  const description_grid_item = (command, description) => {
    return <div className={styles.description_grid_item}><strong>{command}</strong><br />{description}</div>
  }

  const description = () => {
    return <>
      {description_grid_item("aktif", '')}
      {description_grid_item("aktif_degil", '')}
      {description_grid_item("cizim(ID)", 'cizim')}
      {description_grid_item("cizim_ve_varyasyonlari(ID)", 'cizim_ve_varyasyonlari')}
      {description_grid_item("cizim_ve_kardesleri(ID)", 'cizim_ve_kardesleri')}
      {description_grid_item("kesit", '')}
      {description_grid_item("kesit_degil", '')}
      {description_grid_item("ana_cizim", '')}
      {description_grid_item("varyasyon", '')}
      {description_grid_item("pencere", '')}
      {description_grid_item("grup(GROUP_ID)", 'grup IDsine filtereleme')}
      {description_grid_item("grupsuz", '')}
      {description_grid_item("tag_tirnakli_kistirma", '')}
      {description_grid_item("tag_tirnaksiz_kistirma", '')}
      {description_grid_item("tag_alt_tirnakli_kistirma", '')}
      {description_grid_item("tag_alt_tirnaksiz_kistirma", '')}
      {description_grid_item("tag_ust_tirnakli_kistirma", '')}
      {description_grid_item("tag_ust_tirnaksiz_kistirma", '')}
      {description_grid_item("tag_acili_dil", '')}
      {description_grid_item("tag_normal_dil", '')}
      {description_grid_item("tag_pahli_dil", '')}
      {description_grid_item("tag_alt_acili_dil", '')}
      {description_grid_item("tag_alt_normal_dil", '')}
      {description_grid_item("tag_alt_pahli_dil", '')}
      {description_grid_item("tag_ust_acili_dil", '')}
      {description_grid_item("tag_ust_normal_dil", '')}
      {description_grid_item("tag_ust_pahli_dil", '')}
      {description_grid_item("tag_normal_kanat", '')}
      {description_grid_item("tag_acili_kanat", '')}
      {description_grid_item("tag_raduslu_kanat", '')}
      {description_grid_item("tag_alt_normal_kanat", '')}
      {description_grid_item("tag_alt_acili_kanat", '')}
      {description_grid_item("tag_alt_raduslu_kanat", '')}
      {description_grid_item("tag_ust_normal_kanat", '')}
      {description_grid_item("tag_ust_acili_kanat", '')}
      {description_grid_item("tag_ust_raduslu_kanat", '')}
      {description_grid_item("tag_glue", '')}
      {description_grid_item("tag_no_glue", '')}
      {description_grid_item("tag_glue_istege", '')}
      {description_grid_item("tag_dilli", '')}
      {description_grid_item("tag_ust_dilli", '')}
      {description_grid_item("tag_alt_dilli", '')}
      {description_grid_item("tag_parmak_kesigi", '')}
      {description_grid_item("tag_ust_parmak_kesigi", '')}
      {description_grid_item("tag_alt_parmak_kesigi", '')}
      {description_grid_item("tag_askilikli", '')}
      {description_grid_item("tag_ucgen_askilikli", '')}
      {description_grid_item("tag_yuvarlak_askilikli", '')}
      {description_grid_item("tag_ust_separator", '')}
      {description_grid_item("tag_ust_separator_yuvarlak", '')}
      {description_grid_item("tag_ust_separator_kare", '')}
      {description_grid_item("tag_alt_separator", '')}
      {description_grid_item("tag_alt_separator_yuvarlak", '')}
      {description_grid_item("tag_alt_separator_kare", '')}
      {description_grid_item("tag_standart_kulak", '')}
      {description_grid_item("tag_seperatorlu_kulak", '')}
      {description_grid_item("tag_kilitli_kulak", '')}
      {description_grid_item("tag_standart_kapak", '')}
      {description_grid_item("tag_raduslu_kapak", '')}
      {description_grid_item("tag_standart_askilik", '')}
      {description_grid_item("tag_raduslu_askilik", '')}
      {description_grid_item("tag_pahli_askilik", '')}
      {description_grid_item("tag_tasmali_askilik", '')}
    </>
  }

  return (<>
    <tr>
      <td valign="top" width="200"> {t(config.label)} { config.tooltip_text != undefined && <Tooltip title={t(config.tooltip_text)} /> }</td>
      <td align="right">
        <TextAreaInput
          name={config.name}
          placeholder={config.placeholder}
          register={register}
          errors={errors}
          class_name={config.class_name}
          validators={config.validators != undefined ? config.validators : []}
          value={formula}
          cols={config.cols}
          rows="4"
          onChange={onChange}
        />
      </td>
    </tr>
    <tr>
      <td colSpan="2">
       <a class_name="btn" href="#" onClick={toggleShowDescription}> [aciklamalari göster/gizle] </a>
        <div className={styles.description_grid}>
          {descriptionVisible && description()}
        </div>

        {descriptionVisible && <small> <div>
          Bu komutlari nokta ile birlestirebiliriz. Ornek: "grup(6).ana_cizim" => "Kartonlardaki İlaç Tipi Kutularin altindaki ana cizimler"
        </div> </small> }

      </td>
    </tr>
    <tr>
      <td colSpan="2">
        <FilteredDrawings formula={delayedFormula} setDrawingIds={setDrawingIds} />
      </td>
    </tr>
    </>
  )
}

export default DrawingFiltering;
















































