import React, { useState, useEffect }  from 'react';
import { GetRequest } from 'components/common/index.js';
import { SelectFlap } from 'components/show_drawing/redraw_form/index.js';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import Constants from 'components/show_drawing/Constants.jsx';
import MyEventListener from 'components/common/MyEventListener.jsx'

const TagsAsOptions = ( { id, setId, drawing, theme, debug } ) => {
  const key = (tag) => { return tag.flag }
  const [tags, setTags] = useState([]);
  const [values, setValues] = useState( {} );
  const [searchResult, setSearchResult] = useState( id );
  const [availableDrawings, setAvailableDrawings] = useState( [] );

  const [onFlap, setonFlap] = useState([])
  const [onTopFlap, setOnTopFlap] = useState([])
  const [onBottomFlap, setOnBottomFlap] = useState([])

  const [onDustFlap, setonDustFlap] = useState([])
  const [onTopDustFlap, setOnTopDustFlap] = useState([])
  const [onBottomDustFlap, setOnBottomDustFlap] = useState([])

  const [onTuck, setonTuck] = useState([])
  const [onTopTuck, setOnTopTuck] = useState([])
  const [onBottomTuck, setOnBottomTuck] = useState([])

  const [onGlueFlap, setOnGlueFlap] = useState([])
  const [onCover, setOnCover] = useState([])
  const [onHanger, setOnHanger] = useState([])

  const [onMagazineSlots, setOnMagazineSlots] = useState([])
  const [onMagazineSlotId, setOnMagazineSlotId] = useState(id)

  const setValue = (key, value) => {
    setValues( (prev) => {
      prev[ key ] = value;
      return { ...prev }
    } )
  }

  const sortByName = (a, b) => {
    if (a.name < b.name) { return -1 }
    if (a.name > b.name) { return 1 }
    return 0;
  }

  const fetchTagsAsOptions = () => {
    // https://diecuttemplates.com.dev/tags-as-options/72606
    GetRequest({
      url: `/tags-as-options/${id}/${window.I18nLocale}`,
      callback: (data) => {
        setTags( () => data.tags )

        for(let i = 0; i< data.tags.length; i++) {
          setValue(data.tags[i].flag, data.tags[i].value)
        }

        setAvailableDrawings( () => data.available_drawings )

        setonFlap( () => data.on_flap )
        setOnTopFlap( () => data.on_top_flap )
        setOnBottomFlap( () => data.on_bottom_flap )

        setonDustFlap( () => data.on_dust_flap )
        setOnTopDustFlap( () => data.on_top_dust_flap )
        setOnBottomDustFlap( () => data.on_bottom_dust_flap )

        setonTuck( () => data.on_tuck )
        setOnTopTuck( () => data.on_top_tuck )
        setOnBottomTuck( () => data.on_bottom_tuck )

        setOnGlueFlap( () => data.on_glue_flap )
        setOnCover( () => data.on_cover )
        setOnHanger( () => data.on_hanger )
        setOnMagazineSlots( () => (data.on_magazine_slots || []).sort(sortByName) )
      }
    })
  }

  const onChange = (e) => {
    setValue(e.target.name, e.target.checked)
  }

  const onClick = (e, name) => {
    e.preventDefault();
    setValue(name, !values[name])
  }

  const disabled = (key) => {
    var found = false;
    var nextValues = { ...values }
    nextValues[key] = !nextValues[key]

    for(let i = 0; i < availableDrawings.length; i++) {
      var condition = true;

      for (const [key, value] of Object.entries(nextValues)) {
        condition = condition && availableDrawings[i][key] == value
      }
      if (condition) { found = true }
    }

    return !found;
  }

  const inputFor = (tag, i) => {
    var isDisabled = disabled(tag.flag);
    var othersNull = onTuck == undefined && onDustFlap == undefined && onFlap == undefined
    return (
      <div key={tag.flag}>
        <span onClick={ isDisabled ? () => {} : (e) => { onClick(e, tag.flag) }}
            className={`${othersNull && i == (tags.length - 1) ? styles.last_tag : ''} ${isDisabled ? '' : styles.clickable } input-group-addon input-group-addon-${window.locale} ${styles.input_add_on_for_boolean}`}>
         <span className={`${isDisabled ? styles.disabled_label : ''}`}  >
          {t(`models.${tag.flag}`)}
          </span>

          <div className={styles.div_boolean} key={tag.flag}>
            <input
              name={tag.flag}
              key={tag.flag}
              type="checkbox"
              value="1"
              className="drawing-option-input"
              checked={values[tag.flag]}
              disabled={isDisabled}
              autoComplete="off"
              onChange={onChange}
            />
          </div>
        </span>
      </div>
     )
  }

  const onApplyCommand = (msg) => {
    if(msg.detail.id != id) { return }
    let apply_command =  msg.detail.apply_command;
    let parts = apply_command.split('_');
    let last_i = parts.length - 1;
    let apply_command_value  = parts[ last_i ] == "1"
    let apply_command_key = parts.slice(1, last_i ).join('_');
    tags.map( (tag) => {
      if(tag.flag == apply_command_key && tag.value == !apply_command_value) {
        setValue(tag.flag, apply_command_value)
        trackEvent('js_apply_command_applied', { id: id, apply_command: apply_command, key: apply_command_key, value: apply_command_value } );
      }
    })
  }

  // useEffect starts here
  useEffect( () => {
    for(let i = 0; i < availableDrawings.length; i++) {
      var condition = true;

      for (const [key, value] of Object.entries(values)) {
        condition = condition && availableDrawings[i][key] == value
      }

      if(condition) {
        setSearchResult( () => availableDrawings[i].id )
        setId( () => availableDrawings[i].id)
        break;
      }
    }
  }, [values] )

  useEffect(fetchTagsAsOptions, [id])
  useEffect(() => {
    if (id == onMagazineSlotId) { return }
    setId( () => onMagazineSlotId )
  }, [onMagazineSlotId])
  // useEffect ends here

  MyEventListener([{
    key: Constants.drawing_apply_command,
    callback: onApplyCommand
  }])

  const areAllUndefined = (arr) => {
   for(let i = 0; i < arr.length; i++) { if(arr[i] != undefined && arr[i].length > 0) { return false } }
   return true;
  }

  return (<div key="tags-as-options">
      { tags.map(inputFor) }

      {onFlap != undefined && onFlap.length > 0 && <SelectFlap
        key="flap"
        title={t('models.flap')}
        id={id}
        setId={setId}
        drawing={drawing}
        onFlap={onFlap}
        as="flap"
        image='flap'
        cta={t('models.change_flap')}
        cta_replace="%{flap}"
        theme={theme}
        isLast={areAllUndefined([ onTopFlap, onBottomFlap, onDustFlap, onTopDustFlap, onBottomDustFlap, onTuck, onTopTuck, onBottomTuck, onGlueFlap, onCover, onHanger ] )}
      /> }

      {onTopFlap != undefined && onTopFlap.length > 0 && <SelectFlap
        key="top_flap"
        title={t('models.top_flap')}
        id={id}
        setId={setId}
        drawing={drawing}
        onFlap={onTopFlap}
        as="top_flap"
        image='flap'
        cta={t('models.change_top_flap')}
        cta_replace="%{flap}"
        theme={theme}
        isLast={areAllUndefined([ onBottomFlap, onDustFlap, onTopDustFlap, onBottomDustFlap, onTuck, onTopTuck, onBottomTuck, onGlueFlap, onCover, onHanger ] )}
      /> }

      {onBottomFlap != undefined && onBottomFlap.length > 0 && <SelectFlap
        key="bottom_flap"
        title={t('models.bottom_flap')}
        id={id}
        setId={setId}
        drawing={drawing}
        onFlap={onBottomFlap}
        as="bottom_flap"
        image='flap'
        cta={t('models.change_bottom_flap')}
        cta_replace="%{flap}"
        theme={theme}
        isLast={areAllUndefined([ onDustFlap, onTopDustFlap, onBottomDustFlap, onTuck, onTopTuck, onBottomTuck, onGlueFlap, onCover, onHanger ] )}
      /> }

      {onDustFlap != undefined && onDustFlap.length > 0 && <SelectFlap
        key="dust_flap"
        title={t('models.dust_flap')}
        id={id}
        setId={setId}
        drawing={drawing}
        onFlap={onDustFlap}
        as="dust_flap"
        image='dust_flap'
        cta={t('models.change_dust_flap')}
        cta_replace="%{dust_flap}"
        theme={theme}
        isLast={areAllUndefined([ onTopDustFlap, onBottomDustFlap, onTuck, onTopTuck, onBottomTuck, onGlueFlap, onCover, onHanger ] )}
      /> }


      {onTopDustFlap != undefined && onTopDustFlap.length > 0 && <SelectFlap
        key="top_dust_flap"
        title={t('models.top_dust_flap')}
        id={id}
        setId={setId}
        drawing={drawing}
        onFlap={onTopDustFlap}
        as="top_dust_flap"
        image='dust_flap'
        cta={t('models.change_top_dust_flap')}
        cta_replace="%{dust_flap}"
        theme={theme}
        isLast={areAllUndefined([ onBottomDustFlap, onTuck, onTopTuck, onBottomTuck, onGlueFlap, onCover, onHanger ] )}
      /> }

      {onBottomDustFlap != undefined && onBottomDustFlap.length > 0 && <SelectFlap
        key="bottom_dust_flap"
        title={t('models.bottom_dust_flap')}
        id={id}
        setId={setId}
        drawing={drawing}
        onFlap={onBottomDustFlap}
        as="bottom_dust_flap"
        image='dust_flap'
        cta={t('models.change_bottom_dust_flap')}
        cta_replace="%{dust_flap}"
        theme={theme}
        isLast={areAllUndefined([ onTuck, onTopTuck, onBottomTuck, onGlueFlap, onCover, onHanger ] )}
      /> }

      {onTuck != undefined && onTuck.length > 0 && <SelectFlap
        key="tuck"
        title={t('models.tuck')}
        id={id}
        setId={setId}
        drawing={drawing}
        onFlap={onTuck}
        as="tuck"
        image='tuck'
        cta={t('models.change_tuck')}
        cta_replace="%{tuck}"
        theme={theme}
        isLast={areAllUndefined([ onTopTuck, onBottomTuck, onGlueFlap, onCover, onHanger ] )}
      /> }

      {onTopTuck != undefined && onTopTuck.length > 0 && <SelectFlap
        key="top_tuck"
        title={t('models.top_tuck')}
        id={id}
        setId={setId}
        drawing={drawing}
        onFlap={onTopTuck}
        as="top_tuck"
        image='tuck'
        cta={t('models.change_top_tuck')}
        cta_replace="%{tuck}"
        theme={theme}
        isLast={areAllUndefined([ onBottomTuck, onGlueFlap, onCover, onHanger ] )}
      /> }

      {onBottomTuck != undefined && onBottomTuck.length > 0 && <SelectFlap
        key="bottom_tuck"
        title={t('models.bottom_tuck')}
        id={id}
        setId={setId}
        drawing={drawing}
        onFlap={onBottomTuck}
        as="bottom_tuck"
        image='tuck'
        cta={t('models.change_bottom_tuck')}
        cta_replace="%{tuck}"
        theme={theme}
        isLast={areAllUndefined([ onGlueFlap, onCover, onHanger ] )}
      /> }


      {onGlueFlap != undefined && onGlueFlap.length > 0 && <SelectFlap
        key="glue_flap"
        title={t('models.glue_flap')}
        id={id}
        setId={setId}
        drawing={drawing}
        onFlap={onGlueFlap}
        as="glue_flap"
        image=''
        cta={t('models.change_glue_flap')}
        cta_replace="%{glue_flap}"
        theme={theme}
        isLast={areAllUndefined([ onCover, onHanger ] )}
      /> }

      {onCover != undefined && onCover.length > 0 && <SelectFlap
        key="cover"
        title={t('models.cover')}
        id={id}
        setId={setId}
        drawing={drawing}
        onFlap={onCover}
        as="cover"
        image=''
        cta={t('models.change_cover')}
        cta_replace="%{cover}"
        theme={theme}
        isLast={areAllUndefined([ onHanger ] )}
      /> }

      {onHanger != undefined && onHanger.length > 0 && <SelectFlap
        key="hanger"
        title={t('models.hanger')}
        id={id}
        setId={setId}
        drawing={drawing}
        onFlap={onHanger}
        as="hanger"
        image=''
        cta={t('models.change_hanger')}
        cta_replace="%{hanger}"
        theme={theme}
        isLast={true}
      /> }

      {onMagazineSlots != undefined && onMagazineSlots.length > 0 && drawing.magazine_slot != undefined && (
        <div className={styles.container} key="onMagazineSlots" style={{marginBottom: '10px'}}>
          <label className={styles.label}> { t('models.type') } </label>

          <select value={onMagazineSlotId} onChange={(e) => setOnMagazineSlotId( () => e.target.value) }
            className="form-control" >
            {onMagazineSlots.map((onMagazineSlot) => {
              return <option value={onMagazineSlot.drawing_id}> {onMagazineSlot.name} </option>
            }) }
          </select>
        </div>
      )}

    </div>
  )
}

export default TagsAsOptions;
