import React, { useState, useEffect }  from 'react';
import styles from 'components/system_commands/_style.module.scss';
import GetRequest from 'components/common/GetRequest.jsx';

const FilteredDrawings = ( { formula, setDrawingIds } ) => {
  const [drawings, setDrawings] = useState( [] );

  const [selected, setSelected] = useState( [] );
  const [selectAll, setSelectAll] = useState( false );

  const [totalEntries, setTotalEntries] = useState( 0 );
  const [totalPages, setTotalPages] = useState( 0 );
  const [currentPage, setCurrentPage] = useState( 1 );
  const [allDrawingIds, setAllDrawingIds] = useState( [] );

  const debug = false;
  const [sql, setSql] = useState( "" );
  const [error, setError] = useState("")

  const fetchDrawings = () => {
    if(formula == "") { return }

    GetRequest({
      url: `/admin/drawings-filtering?formula=${formula}&page=${currentPage}`,
      callback: ( data ) => {
        setError(() => "")
        setDrawings( () => data.drawings )
        setTotalEntries( () => data.pagination.total_entries )
        setTotalPages ( () => data.pagination.total_pages )
        setCurrentPage( () => data.pagination.current_page )
        setAllDrawingIds( () => data.pagination.drawing_ids )
        setSql( () => data.sql )
      },
      400: (data) => {
        setDrawings( () => [] )
        setError(() => data.message)
      }

    })
  }

  const errorMessage = () => {
    return <div className="error_message">
      { error }
    </div>
  }

  const handleInputClick = (e, id) => {
    if(e.target.checked) {
      setSelected( (prev) => [...prev, id]  );
    } else {
     setSelected( (prev) => prev.filter((item) => item !== id) );
    }
  }

  const Drawing = (data) => {
    return <div className={`${styles.drawings_grid_item} ${ selected.includes(data.id) ? styles.drawings_grid_item_selected : ''} `} >
     <input type="checkbox" data-id={data.id} className={styles.default_input} checked={ selected.includes(data.id) } onChange={ (e) => handleInputClick(e, data.id) } />
      &nbsp; Sec

      <strong style={{ "float": "right" }}> {data.catalog_name_main_drawing_parts} </strong>

      <br /><strong> {data.name} </strong>
      <a href={`/drawings/${data.id}/points`} target="_blank"> <img className={styles.png} src={data.png} /> </a>
      <strong> {data.id} {data.info} </strong> <br />
      <small> { data.breadcrumb_groups_tr } </small> <br />

      {data.variation_of != undefined && <>
        <hr /> <a href={`/drawings/${data.variation_of.id}/points`} target="_blank"> {data.variation_of.id} ({data.variation_of.catalog_name_main_drawing_parts}) nin varyasyonu </a>
      </> }

      {data.variation_ids != undefined && data.variation_ids.length > 0 && <>
        {data.variation_ids.length} ({data.variation_ids.map( (id) => {
          return <><a href={`/drawings/${id}/points`} target="_blank">{id}</a> </>
        } ) } ) varyasyonu var.
      </>}

    </div>
  }

  const pageExists = (num) => {
    if (num == 0 || num < 0) { return false }
    return totalPages >= num;
  }

  const setPage = (num, e) => {
    e.preventDefault();
    setCurrentPage( () => num )
  }

  const pagination = () => {
    return <div className={styles.pagination}>
      Toplam <strong> {totalEntries} </strong> adet cizim bulundu.  &nbsp;  &nbsp;

      Sayfa <strong>  {currentPage}/{totalPages} </strong>
       &nbsp;  &nbsp;  &nbsp;

      { pageExists(currentPage - 1 ) && <button className={`${styles.pagination_button} `} onClick={ (e) => { setPage(currentPage - 1, e) } } > önceki sayfa </button> }
      { !pageExists(currentPage - 1 ) && <button className={`${styles.disabled_button} `} > önceki sayfa </button> }

      { pageExists(currentPage + 1 ) && <button className={`${styles.pagination_button}`} onClick={ (e) => { setPage(currentPage + 1, e) } } > sonraki sayfa </button> }
      { !pageExists(currentPage + 1 ) && <button  className={`${styles.disabled_button} `}  > sonraki sayfa </button> }

      &nbsp;  &nbsp;  &nbsp;

      <strong> {selected.length} </strong>  adet cizim secildi. <input className={styles.default_input} type="checkbox" onClick={(e) => setSelectAll( () => e.target.checked) } checked={selectAll} /> Hepsini sec!
    </div>
  }

  // useEffect starts
  useEffect(fetchDrawings, [formula, currentPage] )

  useEffect(() => {
    if(totalPages < currentPage) {
      setCurrentPage( () => 1 )
    }
  }, [totalPages])

  useEffect( () => {
    setSelected( () => selectAll ? allDrawingIds : [] )
  },  [selectAll])

  useEffect( () => {
    setSelectAll( () => false )
    setSelected( () => [] )
  }, [formula] )

  useEffect( () => { setDrawingIds( () =>  selected ) }, [selected])
  // useEffect ends

  return (
    <div>
      {debug && <>FilteredDrawings {formula}  <br />   <hr /> </> }

      {debug && <div className="debugSql"> { sql }   <hr /> </div>}

      {error != "" && errorMessage() }

      { pagination() }

      <div className={styles.drawings_grid}>
        {drawings.map(Drawing)}
      </div>

      { pagination() }

      <hr />

    </div>
  )
}

export default FilteredDrawings;
