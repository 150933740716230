import React, { useEffect, useRef } from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import MyEventListener from 'components/common/MyEventListener.jsx'

const ConfirmButton = ( {  id, setId, onClose }) => {
  const myRef = useRef();

  const handleClick = (e) => {
    e.preventDefault();
    setId( () => id )
    onClose()
  }

  return (

    <div className={styles.row}>
      <button onClick={handleClick} className="btn simple-btn" ref={myRef}>
        &nbsp; {t('models.confirm_technical_properties')}
      </button>
      <br />
      <br />
    </div>
  )
};

export default ConfirmButton;
