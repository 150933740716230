import React, { useState } from 'react';
const PostRequest = (props) => {
  var dataToPost = props.data;
  if(dataToPost == undefined) { dataToPost = {} }
  dataToPost.authenticity_token = $('meta[name=csrf-token]').attr('content');

  let requestOptions;

  if (dataToPost.file_fields != undefined && dataToPost.file_fields.length > 0) {
    const formData = new FormData();

    for(let i = 0; i < dataToPost.file_fields.length; i++) {
      formData.append(dataToPost.file_fields[i], dataToPost[ dataToPost.file_fields[i] ]);
    }

    Object.keys(dataToPost).forEach(key => {

      for(let i = 0; i < dataToPost.file_fields.length; i++) {
        if (key !== dataToPost.file_fields[i]) {

          switch (true) {
            case Array.isArray(dataToPost[key]):
              dataToPost[key].forEach(value => formData.append(`${key}[]`, value));
              break;

            case typeof dataToPost[key] === "object" && dataToPost[key] !== null:
              Object.entries(dataToPost[key]).forEach(([objKey, objValue]) => {
                formData.append(`${key}[${objKey}]`, objValue);
              });
              break;

            default:
              formData.append(key, dataToPost[key]);
          }

        }
      }

    });

    requestOptions = {
      method: 'POST',
      body: formData, // Do not set Content-Type, browser handles it
    };
  } else {
    requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataToPost),
    };
  }

  updateRequestCount(1);
  fetch(props.url, requestOptions)
    .then(async response => {
      updateRequestCount(-1);
      const isJson = response.headers.get('content-type')?.includes('application/json');
      const data = isJson && await response.json();

      if (!response.ok) {
        const error = (data && data.message) || response.status;

        if(response.status == 500) {
          trackEvent('js_error_happened', {
            source:  "PostRequest returned 500",
            url: props.url,
            dataToPost: dataToPost,
            response: data,
          });
        }

        if(props[response.status] != undefined) {
          props[response.status](data);
        }
        return Promise.reject(error);
      }

      if(response.redirected && props[302] != undefined) {
        return props[302](data);
      }

      props.callback(data);
    })
    .catch((error) => {
      // alert('Sorry there was an error!' );
      console.error('[PostRequest] There was an error!', error);
    });
}

export default PostRequest
