import React, { useState, useEffect, useRef }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';

const SelectFlap = ( { id, setId, drawing, onFlap, theme, as, cta, cta_replace, title, isLast, image } ) => {
  const asOption = (option) => {
    return <div className={`${styles.tp_dropdown_item}`} key={option.drawing_id} onClick={ (e) => { e.preventDefault(); setId( () => option.drawing_id  ) } }>
      <a className={`dropdown-item ${styles.tp_dropdown_item_a}`} href="#" onClick={ (e) => { e.preventDefault(); setId( () => option.drawing_id  ) } }>
        <img style={{ width: "88px" }} className={`additional_feature_img tag_img_${theme}`} src={`https://d2atdwxjx7uc4i.cloudfront.net/images/tags/${image}_${option.id}.png`} />
        <span className={`${styles.tp_value}`}> <input type="radio" /> {option.name} </span>
      </a>
    </div>
  }

  const setDropDown = () => {
    $(".dropdown-menu").width( $(".input_tp").css('width') )
    $(".dropdown-menu").css("margin-left",  (-1 * (parseFloat($(".input_tp").css('width')) - 60)) + "px" )
  }

  useEffect( () => {
    setTimeout(setDropDown, 100)
    setTimeout(setDropDown, 500)
    setTimeout(setDropDown, 1000)
  }, [id] )

  if (drawing[as] == undefined) { return }

  return (
    <div key={drawing.id} className={`${styles.input_tp} input_tp`}  >

       <span className={`${isLast ? styles.last_tag : ''}`} >

            <span className={`${styles.label}`}> {title} </span>

            <div className={`${styles.floatRight}`}>

                { drawing[as].name }  &nbsp;

                <span className={` ${styles.tp_dropdown} dropdown`}>

                  <a className="dropdown-toggle" type="button"  href="#" type="button" id="imageDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fa fa-pencil-square-o"></i>  {t('models.change')}
                  </a>

                  <div className={` ${styles.tp_dropdown_menu} dropdown-menu`} aria-labelledby="imageDropdown">
                    <div className={`${styles.tp_dropdown_item} ${styles.tp_dropdown_item_disabled} `}>

                      <img style={{ width: "88px" }} className={`additional_feature_img tag_img_${theme}`} src={`https://d2atdwxjx7uc4i.cloudfront.net/images/tags/${image}_${drawing[as].id}.png`} />
                      <span className={`${styles.tp_value}`}> <input type="radio" checked={true} />  {drawing[as].name} </span>

                    </div>
                    {onFlap.map(asOption)}
                  </div>
              </span>
          </div>
      </span>

  </div>);

}

export default SelectFlap;
