import React, { useState, useEffect, useRef }  from 'react';
import { GetRequest } from 'components/common/index.js';

import { ContentPopup } from 'components/ContentPopup.jsx';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import TechnicalProperties from 'components/show_drawing/redraw_form/TechnicalProperties.jsx';

const TechnicalPropertiesController = ( { id, unit, setId, theme, debug, optionsUserChanged, formRef} ) => {
  const [workingId, setWorkingId] = useState(id);
  const [drawing, setDrawing] = useState( { image: { } } )
  const [enabled, setEnabled] = useState(false)

  const fetchTagsAsOptions = () => {
    // https://diecuttemplates.com.dev/tags-as-options/72606
    GetRequest({
      url: `/tags-as-options/${workingId}/${window.I18nLocale}`,
      callback: (data) => {
        if( data.tags != undefined && data.tags.length > 0) { setEnabled( () => true ); return }
        if( data.available_drawings != undefined && data.available_drawings.length > 0) { setEnabled( () => true ); return }
        if( data.on_flap != undefined && data.on_flap.length > 0) { setEnabled( () => true ); return }
        if( data.on_top_flap != undefined && data.on_flap.length > 0) { setEnabled( () => true ); return }
        if( data.on_bottom_flap != undefined && data.on_bottom_flap.length > 0) { setEnabled( () => true ); return }
        if( data.on_dust_flap != undefined && data.on_dust_flap.length > 0) { setEnabled( () => true ); return }
        if( data.on_top_dust_flap != undefined && data.on_top_dust_flap.length > 0) { setEnabled( () => true ); return }
        if( data.on_bottom_dust_flap != undefined && data.on_bottom_dust_flap.length > 0) { setEnabled( () => true ); return }
        if( data.on_tuck != undefined && data.on_tuck.length > 0) { setEnabled( () => true ); return }
        if( data.on_top_tuck != undefined && data.on_top_tuck.length > 0) { setEnabled( () => true ); return }
        if( data.on_bottom_tuck != undefined && data.on_bottom_tuck.length > 0) { setEnabled( () => true ); return }
        if( data.on_glue_flap != undefined && data.on_glue_flap.length > 0) { setEnabled( () => true ); return }
        if( data.on_cover != undefined && data.on_cover.length > 0) { setEnabled( () => true ); return }
        if( data.on_hanger != undefined && data.on_hanger.length > 0) { setEnabled( () => true ); return }
        if( data.on_magazine_slots != undefined && data.on_magazine_slots.length > 0) { setEnabled( () => true ); return }

        setEnabled( () => false )
      }
    })
  }

  const getDrawing = () => {
    if(workingId == null) { return }

    GetRequest({
      url: `/drawing-data/${workingId}`,
      callback: (data) => {
        setDrawing( () => data.drawing )
      }
    });

  }

  const [showPopUp, setShowPopUp] = useState(false);

  const onClick = (e) => {
    e.preventDefault();
    setShowPopUp( () => true )
  }

  const popupRef = useRef();
  const popupContent = () => {
    let callback = () => {
      popupRef.current.style.display = "none";
      setShowPopUp(() => false);
    };

    let content = <TechnicalProperties
      id={id}
      unit={unit}
      setId={setId}
      drawing={drawing}
      theme={theme}
      debug={debug}
      workingId={workingId}
      setWorkingId={setWorkingId}
      optionsUserChanged={optionsUserChanged}
      formRef={formRef}
      onClose={callback}
    />

    const modalStyle = {
      "width" : $(".container").width() * 0.92,
      "height" : "96%",
      "maxWidth": "unset"
    }

    return <ContentPopup
      id="technical_properties_modal"
      autoOpen={true}
      myRef={popupRef}
      content={content}
      cancelButton={false}
      beforeClose={callback}
      modalStyle={modalStyle}
      notWide={true}
    />;
  }

  // useEffect
  useEffect(getDrawing, [ workingId ] );
  useEffect( () => { $("#mainDrawingContainer").css('opacity', showPopUp ? "0.1" : "1" ) }, [ showPopUp ] );
  useEffect(fetchTagsAsOptions, [ id ] )
  // useEffect

  if(!enabled) { return <></>}

  return (<>
    <div className={`${styles.row} ${styles.clickable}`} onClick={onClick}>
      <span className={`input-group-addon input-group-addon-${window.locale} ${styles.input_add_on_for_technical_props}`}>
      <a href="#" onClick={onClick}> <i className="fa fa-pencil-square-o"></i>
        {t('models.change_technical_properties')}
      </a>
      </span>
    </div>
    {showPopUp && popupContent()}
    </>
  )
}

export default TechnicalPropertiesController;
