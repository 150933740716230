import React, { useState, useEffect, useRef } from 'react';

import styles from 'components/show_drawing/redraw_form/_style.module.scss';

const DrawingSvg = ( { id, unit, theme, drawing, formRef, debug,  optionsColRef } ) => {
  const minHeight = 600;

  const [height, setHeight] = useState(0);
  const [svg, setSvg] = useState("");
  const [isPreview, setIsPreview] = useState(true);
  const [rules, setRules] = useState([]);
  const [logInRequired, setLogInRequired] = useState(false);
  const [hasRecommendations, setHasRecommendations] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const ValidationRuleRef = useRef();

  const getSvgPanZoom = (id, panZoomValue) => {
    return svgPanZoom(id, {
      zoomEnabled: true,
      controlIconsEnabled: false,
      dblClickZoomEnabled: false,
      mouseWheelZoomEnabled: true,
      preventMouseEventsDefault: true,
      fit: true,
      refreshRate: 'auto',
      center: true,
      minZoom: panZoomValue * 0.8,
      maxZoom: panZoomValue * 15,
      panEnabled: true,
      contain: false,
      onZoom: onSvgPanZoom
    });
  }

  const resizeSvg = () => {
    if(svg == "" ) { return }
    if( $("#svg_technical").length == 0 ) { setTimeout(resizeSvg, 250); return }

    var h = parseInt( $(optionsColRef.current).height() )
    var panZoomValue = 0.95;
    var panZoomInstance = getSvgPanZoom('#svg_technical', panZoomValue);

    if(h < minHeight) { h = minHeight }
    h = h + "px"
    setHeight( () => h );

    $("#svg_technical").height( h );
    panZoomInstance.resize();
    panZoomInstance.fit();
    panZoomInstance.center();
    panZoomInstance.zoom(panZoomValue);

    $("#svg_technical .dmt").hide();
   }

  const getSVGUrl = (drawing) => {
    let mapping = {
      black_bg: {
        in: drawing.image.svg_in_url,
        infr: drawing.image.svg_infr_url,
        mm: drawing.image.svg_url,
      },
      white_bg: {
        in: drawing.image.svg_in_white_bg_url,
        infr: drawing.image.svg_infr_white_bg_url,
        mm: drawing.image.svg_white_bg_url,
      },
      gray_bg: {
        in: drawing.image.svg_in_gray_bg_url,
        infr: drawing.image.svg_infr_gray_bg_url,
        mm: drawing.image.svg_gray_bg_url,
      }
    }
    return mapping[theme][unit]
  }

  const svgStatus = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return Promise.resolve(res)
    } else {
      return Promise.reject(new Error(res.statusText))
    }
  }

  const showDefaultSvg = () => {
    if(drawing.image == undefined) { return }

    var url = getSVGUrl(drawing);

    if(url == undefined) { return }

    fetch(url)
      .then( svgStatus )
      .then( (response) => response.text() )
      .then(function(data) {
        setSvg( () => data.replace('id="svg"', 'id="svg_technical"') );
      }).catch(function(error) {
    console.log({error});
    });
  }

  // useEffect starts.....................................................................
  useEffect(showDefaultSvg, [ drawing ] );
  useEffect(resizeSvg, [ svg ]);
  // useEffect ends


  return (
    <div id="technical_properties_mainDrawingContainer" style={{ height: height }} className={`${false ? styles.svg_in_progress : 'redraw_theme_' + theme } ${isPreview ? '' : styles.svg_is_not_preview  }`}>
      <div dangerouslySetInnerHTML={{__html: svg}}></div>
    </div>
  )
}

export default DrawingSvg;
