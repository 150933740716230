import React, { useState, useEffect }  from 'react';
import styles from 'components/system_commands/_style.module.scss';
import SystemCommand from 'components/system_commands/SystemCommand.jsx';

const ShowPage = ( { config } ) => {
  const [currentPage, setCurrentPage] = useState('');

  return (
    <div className={styles.container}>

      <div className={styles.menu}>
        <button className={`${styles.button} ${currentPage == 'set_drawing_tag' ? styles.active_button : '' }` } onClick={ () => setCurrentPage( () => 'set_drawing_tag' ) }>{t(`system_commands.command_set_drawing_tag`)} </button> <br /> <br />
        <button className={`${styles.button} ${currentPage == 'set_variation_of_id' ? styles.active_button : '' } `} onClick={ () => setCurrentPage( () => 'set_variation_of_id' )} >{t(`system_commands.command_set_variation_of_id`)}   </button> <br /> <br />
        <button className={`${styles.button} ${currentPage == 'delete_drawing' ? styles.active_button : '' } `} onClick={ () => setCurrentPage( () => 'delete_drawing' )} >{t(`system_commands.command_delete_drawing`)}   </button> <br /> <br />
        <button className={`${styles.button} ${currentPage == 'upload_three_d_image' ? styles.active_button : '' } `} onClick={ () => setCurrentPage( () => 'upload_three_d_image' )} >{t(`system_commands.command_upload_three_d_image`)}   </button> <br /> <br />
        <button className={`${styles.button} ${currentPage == 'auto_update_rule_priority' ? styles.active_button : '' } `} onClick={ () => setCurrentPage( () => 'auto_update_rule_priority' )} >{t(`system_commands.command_auto_update_rule_priority`)}   </button> <br /> <br />
      </div>

      <div className={styles.main}>
        <div key={currentPage} id={currentPage}>
          {currentPage != '' && <SystemCommand command_key={currentPage} config={config} /> }
        </div>
      </div>

    </div>
  )
}

export default ShowPage;
