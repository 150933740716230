import React, { useState, useEffect, useRef }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import TagsAsOptions from 'components/show_drawing/redraw_form/technical_properties/TagsAsOptions.jsx';
import DrawingSvg from 'components/show_drawing/redraw_form/technical_properties/DrawingSvg.jsx';
import ConfirmButton from 'components/show_drawing/redraw_form/technical_properties/ConfirmButton.jsx';

const TechnicalProperties = ( { id, unit, setId, drawing, theme, debug, workingId, setWorkingId, optionsUserChanged, formRef, onClose} ) => {
  const optionsColRef = useRef();

  useEffect( () => {
    $("#technical_properties_left").height( $("#technical_properties_modal").height()  )
  }, [])

  return (
    <div className={styles.tp_div} id="technical_properties">

      <div className={styles.tp_left} ref={optionsColRef} id="technical_properties_left">

        <TagsAsOptions
          id={id}
          setId={setId}
          theme={theme}
          debug={debug}
          workingId={workingId}
          setWorkingId={setWorkingId}
          drawing={drawing}
        />

        <br />

        { false && <img src = "https://placehold.co/500x1400" /> }

        <ConfirmButton id={workingId} setId={setId} onClose={onClose} />

      </div>

      <div className={styles.tp_right}>
        <DrawingSvg
          id={workingId}
          drawing={drawing}
          optionsColRef={optionsColRef}
          theme={theme}
          optionsUserChanged={optionsUserChanged}
          formRef={formRef}
          unit={unit}
          />
      </div>

    </div>
  )
}

export default TechnicalProperties;
